import React from 'react';
import { FaWhatsapp, FaInstagram, FaFacebook } from 'react-icons/fa';
import './NavBar.css';
function NavBar(){
    const handleInstagramClick = () => {
    
      const isInstagramAppInstalled = window.navigator.userAgent.includes('Instagram') || window.navigator.userAgent.includes('אינסטגרם');
      if (isInstagramAppInstalled) {
          window.location.href = 'intent://user/blinov.arthur#Intent;package=com.instagram.android;scheme=https;end';
        } else {
          window.open('https://www.instagram.com/blinov.arthur/', '_blank');
        }
      };
    
      const handleFacebookClick = () => {
        const isFacebookAppInstalled = window.navigator.userAgent.includes('FBAN') || window.navigator.userAgent.includes('FBAV');
        if (isFacebookAppInstalled) {
          window.location.href = 'fb://facewebmodal/f?href=https://www.facebook.com/blinov.arthur?mibextid=ZbWKwL';
        } else {
          window.open('https://www.facebook.com/blinov.arthur/', '_blank');
        }
      };
         
  return (
    <nav className="navbar">
      {/* //https://wa.me/message/EC3HPMSUST4QI1// */}
  <div className="navbar-line"></div>
  <div className="navbar-icons">
    <a href="https://wa.me/+972547962374/?text=Hello, AR Marketing" className="navbar-icon"><FaWhatsapp /></a>
    <a onClick={handleFacebookClick} className="navbar-icon"><FaFacebook /></a>
    <a onClick={handleInstagramClick}  className="navbar-icon"><FaInstagram /></a>
  </div>
  <div className="navbar-line"></div>
</nav>
  );
}

export default NavBar;
