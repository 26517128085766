import React, {useEffect, useState} from 'react'
import './AboutMe.css';
import me from '../images/me.jpg'


function AboutMe() {
  const [shouldShowNavigation, setShouldShowNavigation] = useState(true);
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    const handleMediaQueryChange = (event) => {
      setShouldShowNavigation(!event.matches);
    };

    mediaQuery.addEventListener('change', handleMediaQueryChange);
    setShouldShowNavigation(!mediaQuery.matches);

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);
    return (
        <div className="third-component">
        <img src={me} alt="My Photo" className="photo" />
        <div className="line-animation"></div>
        <div className="content">
          <h2 className="title">Expert Software Developer</h2>
          <p className="description">
          I am an experienced software developer specializing in advanced application and website development, as well as custom software solutions. I invest significant time and effort in understanding the needs of my clients and finding efficient and advanced solutions. I always strive to stay up to date with the latest technologies and provide added value to the projects I work on. I am constantly seeking new opportunities and would be delighted to work with you to elevate your project to a high level.
          </p>
        </div>
      </div>

      );
}

export default AboutMe