import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import one from '../images/01.jpg';
import two from '../images/02.jpg';
import three from '../images/03.jpg'
import four from '../images/04.jpg';
import five from '../images/05.jpg';
import './FormAndBackgrounds.css'
import { FaPhone } from 'react-icons/fa';
import {BsEnvelopeOpen} from 'react-icons/bs';

function FormAndBackgrounds() {

    

const images = [
  one, two, three, four, five
];


const [index, setIndex] = useState(0);
const name = useRef('');
const telephone = useRef('');
const email = useRef('');
const sendButtonRef = useRef(null);
const [isSending, setIsSending] = useState(false);
const [isCallButtonRotating, setIsCallButtonRotating] = useState(false);
const [showNotification, setShowNotification] = useState(false);
const [notificationMessage, setNotificationMessage] = useState('');
useEffect(() => {
  const timer = setInterval(() => {
    setIndex((prevIndex) => (prevIndex + 1) % images.length);
  }, 5000);

  return () => clearInterval(timer);
}, []);

const sendMail = async () => {

  if (name.current.value.toString() === '' && telephone.current.value.toString() && '' || email.current.value.toString() === '') {
    setNotificationMessage('The details are incorrect');
    setShowNotification(true);
    return;
  }

//   if (!telephone.current.value.toString().startsWith('0') || telephone.current.value.toString().length !== 10) {
//     setNotificationMessage('הפרטים לא נכונים');
//     setShowNotification(true);
//     return;
//   }

  if (!email.current.value.toString().includes('@')) {
    setNotificationMessage('The details are incorrect');
    setShowNotification(true);
    return;
  }

  // Send email logic here
  
  

  try {
    setIsSending(true);
    const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      'Content-Type': 'application/json',
      "Access": "application/json"
    },
  }
    await axios.post('https://my-back-for-emails-18-06-d237bc00febe.herokuapp.com/send-email/', {
        name: name.current.value,
        telephone: telephone.current.value,
        email: email.current.value,
    }, config);
  
    name.current.value = '';
    telephone.current.value = '';
    email.current.value = '';
    setIsSending(false);
    setNotificationMessage('The email is sent to us');
    setShowNotification(true);
  } catch (error) {
    name.current.value = '';
    telephone.current.value = '';
    email.current.value = '';
    setIsSending(false);
    setNotificationMessage('The email is sent to us')
    setNotificationMessage(true);
  }
};



  const handleCallButtonClick = () => {
    setIsCallButtonRotating(true);
    // Add your logic to handle the call action
  };
  return (
    <div>
       <section className="contact-section">
      <div className="background">
        {images.map((image, i) => (
          <img
            key={i}
            src={image}
            alt={`Background ${i + 1}`}
            className={i === index ? 'active' : ''}
          />
        ))}
      </div>
      <div className="contact-form-container">
        <h2>Contact Us</h2>
        <form className="contact-form">
          <input type="text" placeholder="name" ref={name} />
          <input type="tel" placeholder="mobile" ref={telephone} />
          <input type="email" placeholder="email" ref={email} />
          <button
            type="button"
            ref={sendButtonRef}
            className={isSending ? 'sending' : ''}
            onClick={sendMail}
          >
            {isSending ? 'sending...' : <BsEnvelopeOpen />}
          </button>
          {showNotification && (
        <div className="notification">
          <p className={`${notificationMessage}` === 'The details are incorrect' ? 'red' : 'send'}>{notificationMessage}</p>
          <button type="button" onClick={() => setShowNotification(false)}>Close</button>
        </div>
      )}
        </form>
        <a 
            href="tel:+972-54-796-2374"
            className={`call-button ${isCallButtonRotating ? 'rotate' : ''}`}
            onMouseEnter={handleCallButtonClick}
        >
        <FaPhone />
        </a>
    </div>
    </section>
    </div>
  )
}

export default FormAndBackgrounds