import React, {useState, useRef, useEffect} from 'react';
import { FaDesktop, FaMobileAlt, FaLifeRing, FaSearch, FaShareAlt, FaShieldAlt } from 'react-icons/fa';
import './Services.css';
import axios from 'axios';
import {BsEnvelopeOpen} from 'react-icons/bs';

const Services = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const name = useRef('');
    const telephone = useRef('');
    const email = useRef('');
    const [isSending, setIsSending] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const sendButtonRef = useRef(null);
    const middleRef = useRef(null);

  useEffect(() => {
    // Scroll to the middle section when the component mounts
    middleRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

    const handleClick = (title) => {
        setShowPopup(true);
        setInputValue(`I also want - ${title}`);
    };

    const sendMail = async () => {

        if (name.current.value.toString() === '' && telephone.current.value.toString() && '' || email.current.value.toString() === '') {
          setNotificationMessage('The details are incorrect');
          setShowNotification(true);
          return;
        }
      
        // if (!telephone.current.value.toString().startsWith('0') || telephone.current.value.toString().length !== 10) {
        //   setNotificationMessage('הפרטים לא נכונים');
        //   setShowNotification(true);
        //   return;
        // }
      
        if (!email.current.value.toString().includes('@')) {
          setNotificationMessage('The details are incorrect');
          setShowNotification(true);
          return;
        }
      
        // Send email logic here
        
        const config = {
          headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              "Accept": 'application/json'
          }
        }
        const emailWithText = email.current.value + ' ' + inputValue; 
        try {
          setIsSending(true);
          await axios.post('https://my-back-for-emails-18-06-d237bc00febe.herokuapp.com/send-email/', {
              name: name.current.value,
              telephone: telephone.current.value,
              email: emailWithText,
          }, config);
        
          name.current.value = '';
          telephone.current.value = '';
          email.current.value = '';
          setInputValue('')
          setIsSending(false);
          setNotificationMessage('Your email is sent to us');
          setShowNotification(true);
        } catch (error) {
          name.current.value = '';
          telephone.current.value = '';
          email.current.value = '';
          setInputValue('')
          setIsSending(false);
          setNotificationMessage(error);
          setShowNotification(true);
        }
      };
  return (
    <div className="services" ref={middleRef}>
      <div className="service">
        <FaDesktop onClick={() => handleClick('Website')} className="service-icon" />
        <h3 className="service-title">Website Development</h3>
        <p className="service-description">Customized website development for business purposes</p>
      </div>
      <div className="service">
        <FaMobileAlt onClick={() => handleClick('Mobile App')} className="service-icon" />
        <h3 className="service-title">App development</h3>
        <p className="service-description">Mobile app development for business purposes</p>
      </div>
      <div className="service">
        <FaLifeRing onClick={() => handleClick('Technical support')} className="service-icon" />
        <h3 className="service-title">24/7 technical support</h3>
        <p className="service-description">Round-the-clock technical support to ensure smooth operation of your systems.</p>
      </div>
      <div className="service">
        <FaSearch onClick={() => handleClick('Website and app promotion')} className="service-icon" />
        <h3 className="service-title">Website and app promotion</h3>
        <p className="service-description">We perform optimization of your website to improve its visibility in search engines.</p>
      </div>
      <div className="service">
        <FaShareAlt onClick={() => handleClick('Social Media Promotion')} className="service-icon" />
        <h3 className="service-title">Social media promotion</h3>
        <p className="service-description">We help you grow and expand online through effective social media promotion.</p>
      </div>
      <div className="service">
        <FaShieldAlt onClick={() => handleClick('Website or Mobile App Security')} className="service-icon" />
        <h3 className="service-title">Website or mobile application security</h3>
        <p className="service-description">We take care of securing your websites and applications from potential threats.</p>
      </div>
      {showPopup && (
        <div className="popup">
          <form>
          <input type="text" placeholder="name" ref={name} />
          <input type="tel" placeholder="mobile" ref={telephone} />
          <input type="email" placeholder="email" ref={email} />
          
            <textarea className="ticker" onChange={(e) => setInputValue(e.target.value)}>
            {inputValue}</textarea>
          <button
            type="button"
            ref={sendButtonRef}
            className={isSending ? 'button sending' : 'button'}
            onClick={sendMail}
          >
            {isSending ? 'sending...' : <BsEnvelopeOpen />}
          </button>
          <button className="button close-button">
                close window
          </button>
          {showNotification && (
        <div className="notification">
          <p className={`${notificationMessage}` === 'The details are incorrect' ? 'red' : 'send'}>{notificationMessage}</p>
          <button type="button" onClick={() => setShowNotification(false)}>Close</button>
         
        </div>
      )}
          </form>
        </div>
      )}
    </div>
  );
};

export default Services;