import React from 'react'
import './Footer.css';
import { FaWhatsapp, FaFacebook, FaInstagram } from 'react-icons/fa';
function Footer() {
    const handleInstagramClick = () => {
    
        const isInstagramAppInstalled = window.navigator.userAgent.includes('Instagram');
          if (isInstagramAppInstalled) {
            window.location.href = 'instagram://user?username=blinov.arthur';
          } else {
            window.open('https://www.instagram.com/blinov.arthur/', '_blank');
          }
        };
      
        const handleFacebookClick = () => {
          const isFacebookAppInstalled = window.navigator.userAgent.includes('FBAN') || window.navigator.userAgent.includes('FBAV');
          if (isFacebookAppInstalled) {
            window.location.href = 'fb://facewebmodal/f?href=https://www.facebook.com/blinov.arthur?mibextid=ZbWKwL';
          } else {
            window.open('https://www.facebook.com/blinov.arthur/', '_blank');
          }
        };
  return (
    <div>
           <footer className="footer">
      <div className="social-icons">
        <a href="https://wa.me/+972547962374/?text=Hello, AR Marketing" target="_blank" rel="noopener noreferrer" className="social-icon">
          <FaWhatsapp />
        </a>
        <a onClick={handleFacebookClick}  className="social-icon">
          <FaFacebook />
        </a>
        <a onClick={handleInstagramClick} className="social-icon">
          <FaInstagram />
        </a>
      </div>
      <p className="credit">Copyright 2023. Arthur B's website</p>
    </footer>
    </div>
  )
}

export default Footer