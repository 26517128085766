import React from 'react'
import FormAndBackgrounds from './components/FormAndBackgrounds';
import { Helmet } from 'react-helmet';
import Services from './components/Services';
import AboutMe from './components/AboutMe';
function Main() {
    return (
    <div>
        <Helmet>
        <title>AR Marketing</title>
        <meta name="description" content="landing page, web development, app development" />
        <meta name="keywords" content="AR Marketing, landing page, web development, app development" />
        <meta name="AR Marketing" content="AR Marketing" />
        <meta property="og:type" content="website" />
      </Helmet>
        <FormAndBackgrounds/>
        <Services/>
        <AboutMe/>
    </div>
  )
}

export default Main